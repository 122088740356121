<template>
  <div class="home">
    <div class="slider">
      <Slider/>
    </div>
    <div class="content">
      <div class="container">
        <div class="section project recommend" :class="{skeleton: !state.projects.recommend.loaded}">
          <div class="title">
            <h3>
              <b>추천 프로젝트</b>
            </h3>
          </div>
          <template v-if="state.projects.recommend.loaded">
            <ul class="cards no-scrollbar tight clearfix" v-if="state.projects.recommend.list.length">
              <li v-for="(p, idx) in state.projects.recommend.list" :key="idx + 1"> <!-- idx + 1 하는 이유 : 하단의 스켈레톤 UI와 키를 맞추기 위해(progress-bar 애니메이션 효과 가능)-->
                <Card
                    page="home"
                    :projectSeq="p.projectSeq"
                    :link="p.linkUrl"
                    :thumbFilePath="p.thumbFilePath"
                    :amount="p.investAmt"
                    :projectName="p.projectName"
                    :progressOrder="p.progressOrder"
                    :projectCate="p.projectCate"
                    :fundingType="p.fundingType"
                    :count="p.investorCount"
                    :percent="Number(p.per)"
                    :dday="Number(p.dday) === 0 ? 0 : Number(p.dday || p.dDay)"
                    :builderName="p.builderName"
                    :builderImageUrl="p.builderImageUrl"
                    :builderSeq="p.builderSeq"
                    :projectType="p.projectType"
                    :interest="true"
                    :mainImagePosition="p.mainImagePosition"
                />
              </li>
            </ul>
            <div class="pt-50 pb-50" v-else>
              <NoData/>
            </div>
          </template>
          <ul class="cards no-scrollbar tight clearfix" v-else>
            <li v-for="i in 4" :key="i">
              <Card
                  :skeleton="true"
                  builderName="wait a moment"
                  projectName="wait"
                  projectCate="wait"
                  page="home"
                  :count="0"
                  :amount="0"
                  :percent="0"
                  :interest="true"
              />
            </li>
          </ul>
        </div>
        <div class="section project popular" :class="{skeleton: !state.projects.popular.loaded}">
          <div class="title">
            <h3>
              <b>인기 프로젝트</b>
            </h3>
            <div class="side" v-if="state.projects.popular.category.visible">
              <div class="form-check form-check-inline font-sm" :class="{ 'active': c.name === state.projects.popular.category.active }" v-for="(c, idx) in state.projects.popular.category.list" :key="idx">
                <label class="radio-dot">
                  <input class="form-check-input" type="radio" :id="'popularCategory' + idx" v-model="state.projects.popular.category.active" :value="c.name" @change="changeCategory()" :disabled="!state.projects.popular.loaded">
                  <span></span>
                </label>
                <label class="form-check-label" :for="'popularCategory' + idx">{{ c.title }}</label>
              </div>
            </div>
          </div>
          <template v-if="state.projects.popular.loaded">
            <ul class="cards no-scrollbar tight clearfix" v-if="state.projects.popular.list.length">
              <li v-for="(p, idx) in state.projects.popular.list" :key="idx + 1">
                <Card
                    page="home"
                    :projectSeq="p.projectSeq"
                    :link="state.projects.popular.category.active === 'reward' ? '/reward/' + p.projectSeq : '/invest/' + p.investSeq"
                    :thumbFilePath="p.thumbFilePath"
                    :amount="p.expenseAmt"
                    :projectName="p.projectName"
                    :progressOrder="p.progressOrder ? Number(p.progressOrder) : p.progressOrder"
                    :projectCate="p.projectCateName"
                    :fundingType="p.fundingType"
                    :count="p.investorCount"
                    :percent="state.projects.popular.category.active === 'reward'? Number(p.per): Number(p.subscribeRate)"
                    :dday="Number(p.dday) === 0 ? 0 : Number(p.dday || p.dDay)"
                    :builderName="p.builderName"
                    :builderImageUrl="p.builderImageUrl"
                    :builderSeq="p.builderSeq"
                    :projectType="state.projects.popular.category.active"
                    :interest="true"
                    :mainImagePosition="p.mainImagePosition"
                />
              </li>
            </ul>
            <div class="pt-50 pb-50" v-else>
              <NoData/>
            </div>
          </template>
          <ul class="cards no-scrollbar tight clearfix" v-else>
            <li v-for="i in 4" :key="i">
              <Card :skeleton="true"
                    builderName="wait a moment"
                    projectName="wait"
                    projectCate="wait"
                    page="home"
                    :count="0"
                    :amount="0"
                    :percent="0"
              />
            </li>
          </ul>
        </div>
        <div class="banner" v-if="state.banner.title">
          <router-link class="shadow" :to="state.banner.bannerUrl">
            <span class="background" :style="{ backgroundImage: `url(${$definitions.urls.imgCdn + state.banner.imagePath + state.banner.imageSaveName})` }" v-if="state.banner.imageSaveName"></span>
            <div class="title">
              <span class="top" :style="{color: state.banner.titleColor && `${state.banner.titleColor}`}">{{ state.banner.title }}</span>
              <p class="sub" :style="{color: state.banner.subTitleColor && `${state.banner.subTitleColor}`}">{{ state.banner.communityDesc }}</p>
            </div>
          </router-link>
        </div>
        <div class="section planning" :class="{skeleton: !state.projects.planning.loaded}">
          <div class="title">
            <h3>
              <b>기획전</b>
            </h3>
          </div>
          <HomePlanning :loaded.sync="state.projects.planning.loaded" :runWhenEmpty="hidePlanning"/>
        </div>
        <div class="section project new" :class="{skeleton: !state.projects.new.loaded}">
          <div class="title">
            <h3>
              <b>신규 프로젝트</b>
            </h3>
          </div>
          <template v-if="state.projects.new.loaded">
            <ul class="cards no-scrollbar tight clearfix" v-if="state.projects.new.list.length">
              <li v-for="(p, idx) in state.projects.new.list" :key="idx + 1">
                <Card
                    page="home"
                    :projectSeq="p.projectSeq"
                    :link="p.linkUrl"
                    :thumbFilePath="p.thumbFilePath"
                    :amount="p.targetAmt"
                    :projectName="p.projectName"
                    :progressOrder="p.progressOrder"
                    :fundingType="p.fundingType"
                    :count="p.investorCount"
                    :percent="p.per"
                    :dday="Number(p.dday)"
                    :builderName="p.builderName"
                    :builderImageUrl="p.builderImageUrl"
                    :builderSeq="p.builderSeq"
                    :projectType="p.projectType"
                    :interest="true"
                    :noProfile="true"
                    :mainImagePosition="p.mainImagePosition"
                />
              </li>
            </ul>
            <div class="pt-50 pb-50" v-else>
              <NoData/>
            </div>
          </template>
          <ul class="cards no-scrollbar tight clearfix" v-else>
            <li v-for="i in 4" :key="i">
              <Card :skeleton="true" projectName="Wait a moment"/>
            </li>
          </ul>
        </div>
        <div class="section project reservation" :class="{skeleton: !state.projects.new.loaded}" v-if="!state.projects.reservation.loaded || state.projects.reservation.list.length">
          <div class="title">
            <h3>
              <router-link class="bold no-underline" to="/reservation/list">오픈예정 프로젝트</router-link>
            </h3>
            <div class="side">
              <router-link class="font-xs" to="/reservation/list">
                <span class="mr-2">전체보기</span>
                <i class="fa fa-angle-right"></i>
              </router-link>
            </div>
          </div>
          <ul class="cards no-scrollbar tight clearfix" v-if="state.projects.reservation.loaded">
            <li v-for="p in computedReservationProjects" :key="'r' + p.reservationSeq">
              <ReservationCard
                  page="home"
                  :applyFlag="p.applyFlag.toString()"
                  :applyCnt="Number(p.applyCnt)"
                  :clickable="Boolean(p.content) || true"
                  :item="p.item"
                  :memberSeq="p.memberSeq"
                  :memberName="p.corporateName || p.memberName"
                  :reservationCate="p.reservationCate"
                  :reservationSeq="p.reservationSeq"
                  :link="`/reservation/${p.reservationSeq}`"
                  :thumbFilePath="p.thumbnailPath"
                  :projectName="p.projectName"
                  :projectSeq="p.projectSeq.toString()"
                  :mainImagePosition="p.mainImagePosition"
              />
            </li>
          </ul>
          <ul class="cards no-scrollbar tight clearfix" v-else>
            <li v-for="i in 4" :key="i">
              <ReservationCard :skeleton="true" projectName="Wait a moment"/>
            </li>
          </ul>
        </div>
        <div class="section news" :class="{skeleton: !state.projects.news.loaded}" v-if="state.projects.news.list">
          <div class="title">
            <h3>
              <b>최근 소식</b>
            </h3>
          </div>
          <div class="row no-scrollbar newses">
            <div class="col-12 col-lg-6" v-for="(n, idx) in state.projects.news.list" :key="idx" :class="{skeleton: n.skeleton}">
              <div class="wrapper" @click.stop="openNews(n, n.skeleton)">
                <div class="thumbnail">
                  <div :style="{backgroundImage: `url(${computedThumbnailUrl(n.thumbFilePath)})`, backgroundPosition: n.mainImagePosition || 'center'}"></div>
                </div>
                <div class="right-side">
                  <div class="top">
                    <div class="info">
                      <span class="flag">{{ n.projectType === "reward" ? "후원형" : "증권형" }}</span>
                      <span class="project-name">{{ n.projectName }}</span>
                    </div>
                    <span class="date">{{ n.createDate }}</span>
                  </div>
                  <div class="news-title">
                    <b>{{ n.title }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="actions" :class="{skeleton: state.projects.news.more || !state.projects.news.loaded}">
            <button class="btn btn-default" @click="loadNews(true)" :disabled="state.projects.news.more || !state.projects.news.loaded"
                    v-if="state.projects.news.loaded && state.projects.news.paginationInfo && state.projects.news.paginationInfo.lastPageNo > state.projects.news.args.pageIndex">더보기
            </button>
            <span class="fold" v-if="state.projects.news.args.pageIndex > 1" @click="loadNews()">접기</span>
          </div>
        </div>
        <div class="section partners" :class="{skeleton: !state.projects.partnership.loaded}">
          <div class="title">
            <h3>
              <router-link class="bold no-underline" to="/partnership/partnerlist">파트너십</router-link>
            </h3>
            <div class="side">
              <router-link class="font-xs" to="/partnership/partnerlist">
                <span class="mr-2">전체보기</span>
                <i class="fa fa-angle-right"></i>
              </router-link>
            </div>
          </div>
          <Partnership :loaded.sync="state.projects.partnership.loaded"/>
        </div>
      </div>
    </div>
    <div class="news-letter" :id="`${component.name}NewsLetter`">
      <a href="https://ohmarket.stibee.com/" class="ico" target="_blank" rel="noopener noreferrer" title="뉴스레터 바로가기"></a>
    </div>
    <div class="popup" v-if="state.popup">
      <div class="wrapper">
        <b>&lt;긴급공지&gt;</b>
        <div>금일 접속량 폭증으로 인해 오후 5시 이후부터 페이지 로딩 속도가 느려지고 있습니다. 회원님들의 이용에 불편함을 드려 죄송합니다.</div>
        <div>현재 문제점을 파악하여 해결중이오니 조금만 기다려주시면 감사하겠습니다.</div>
      </div>
      <div class="bottom">
        <div class="wrapper">
          <a @click="state.popup = false">닫기</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import Slider from "@/components/Slider.vue";
import Card from "@/components/Card.vue";
import NoData from "@/components/NoData.vue";
import HomePlanning from "./HomePlanning.vue";
import Partnership from "./HomePartnership.vue";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import ReservationCard from "@/components/ReservationCard";
import track from "@/scripts/track";
import MemberPicture from "@/components/MemberPicture";
import store from "@/scripts/store";
import env from "@/scripts/env";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "pageMainHome";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {MemberPicture, ReservationCard, Slider, Card, NoData, HomePlanning, Partnership},
  setup() {
    const component = new Component(() => {
      // console.log(lib.getDecodedObject(`eyJzb3VyY2UiOiJnb29nbGUiLCJtZWRpdW0iOiJkaXNwbGF5IiwiY2FtcGFpZ24iOiJyZXdhcmQtMTI5OTUiLCJjb250ZW50IjoiIiwidGVybSI6IiJ9`));
      // console.log(lib.getEncodedString({
      //   source: "banner",
      //   medium: "main",
      //   campaign: "PCNC",
      //   content: "",
      //   term: "",
      // }));

      const promises = [];
      const categories = ["recommend", "popular", "new", "reservation"];

      loadNews();

      http.get("/api/main/popular-tab", undefined, {cache: true}).then(({data}) => {
        state.projects.popular.category.visible = data.body === "Y";
      });

      http.get("/api/main/banners/middle", undefined, {cache: true}).then(({data}) => {
        if (!data?.body?.length || !data.body[0].title) {
          return;
        }

        state.banner = data.body[0];

        if (state.banner.bannerUrl?.startsWith("https://")) {
          state.banner.bannerUrl = new window.URL(state.banner.bannerUrl).pathname;
        }
      });

      for (let c of categories) {
        const args = {
          category: c,
          projectType: "reward",
        };

        state.projects[c].loaded = false;
        promises.push(http.get("/api/main/projects", args, {cache: true}));
      }

      Promise.all(promises).then((responses) => {
        for (let i in responses) {
          state.projects[categories[i]].loaded = true;
          state.projects[categories[i]].list = [];

          for (let d of responses[i].data.body) {
            state.projects[categories[i]].list.push(d);
          }
        }

        track.post({
          name: "mainHome",
          category: "메인",
          topic: "조회"
        });
      });
    });

    const state = reactive({
      popup: false,
      projects: {
        recommend: {
          list: [],
          loaded: false,
          category: {
            visible: false,
            active: "",
            list: [],
          },
        },
        popular: {
          list: [],
          loaded: false,
          category: {
            visible: false,
            active: "reward",
            list: [
              {name: "reward", title: "후원형"},
              {name: "invest", title: "증권형"},
            ],
          },
        },
        new: {
          list: [],
          loaded: false,
          category: {
            visible: false,
            active: "",
            list: [],
          },
        },
        reservation: {
          list: [],
          loaded: false,
        },
        planning: {
          loaded: false,
        },
        partnership: {
          loaded: false,
        },
        news: {
          loaded: false,
          more: false,
          list: [],
          paginationInfo: null,
          args: {
            pageIndex: 1,
            pageSize: 5,
            pageUnit: 6,
          }
        }
      },
      banner: {
        title: "",
        communityDesc: "",
        imagePath: "",
        imageSaveName: "",
        bannerUrl: ""
      }
    });

    const computedReservationProjects = computed(() => {
      const limit = state.projects.reservation.list.length >= 8 ? 8 : 4;
      return state.projects.reservation.list.filter((p, idx) => idx < limit);
    });

    const computedThumbnailUrl = computed(() => {
      return (thumbFileUrl) => {
        return store.getters.thumbnailUrl(thumbFileUrl);
      };
    });

    const loadNews = (more) => {
      if (env.device === "mobile") {
        state.projects.news.args.pageUnit = 4;
      }

      for (let i = 0; i < state.projects.news.args.pageUnit; i += 1) {
        state.projects.news.list.push({
          projectName: "Wait a moment",
          projectType: "Wait",
          createDate: "0000-00-00",
          title: "Wait a moment",
          skeleton: true,
        });
      }

      if (more) {
        state.projects.news.args.pageIndex += 1;
        state.projects.news.more = true;
      } else {
        state.projects.news.args.pageIndex = 1;
        state.projects.news.loaded = false;
      }

      http.get("/api/main/news", state.projects.news.args).then(({data}) => {
        if (more) {
          state.projects.news.more = false;
          state.projects.news.list = state.projects.news.list.filter(n => !n.skeleton);
          for (let i in data.body.list) {
            state.projects.news.list.push(data.body.list[i]);
          }
          return;
        }

        state.projects.news.loaded = true;
        state.projects.news.list = data.body.list;
        state.projects.news.paginationInfo = data.body.paginationInfo;
      });
    };

    const openNews = (news, loading) => {
      if (loading || !state.projects.news.loaded) {
        return;
      }

      store.commit("openModal", {
        name: "News",
        params: {
          projectSeq: news.projectSeq,
          projectType: news.projectType,
          newsSeq: news.newsSeq,
          page: "home"
        }
      });
    };

    const changeCategory = () => {
      const args = {
        category: "popular",
        projectType: state.projects.popular.category.active,
      };

      state.projects.popular.loaded = false;

      http.get("/api/main/projects", args, {cache: true}).then(({data}) => {
        state.projects.popular.loaded = true;
        state.projects.popular.list = data.body;
      }).catch(httpError(() => {
        state.projects.popular.loaded = true;
      }));
    };

    const hidePlanning = () => {
      document.querySelector(".section.planning")?.remove();
    };

    const onScroll = () => {
      const $newsLetter = document.getElementById(`${component.name}NewsLetter`);
      lib.isClimbable() ? $newsLetter.style.bottom = "70px" : $newsLetter.style.bottom = "0";
    };

    onMounted(() => {
      store.commit("addListener", [component.name, "onScroll", onScroll]);
    });

    return {component, state, computedReservationProjects, computedThumbnailUrl, changeCategory, openNews, loadNews, hidePlanning};
  },
});
</script>

<style lang="scss" scoped>
$gapUnderTitle: $px20;
$gapUnderTitleMobile: $px18;

.home {
  > .slider {
    //padding-bottom: $px50;

    overflow: hidden;
  }

  > .content {
    margin-top: $px50;

    .section {
      width: 100%;
      margin-bottom: $px30;

      .title {
        position: relative;
        margin-bottom: $gapUnderTitle;
        padding-left: $px2;
        padding-right: $px90;

        h3 {
          margin: 0;
          font-size: $px22;

          a, b, span {
            vertical-align: middle;
          }

          a, b {
            font-weight: 600;
            color: #464646;
          }
        }

        .side::v-deep {
          position: absolute;
          top: 0;
          right: 0;

          a {
            text-decoration: none;
          }
        }
      }

      &.recommend .side a {
        font-size: $px13;
      }

      &.popular {
        margin-top: $px25;

        .title {
          padding-right: $px155;
        }

        .side {
          padding-top: $px4;

          .radio-dot {
            display: inline-block;
            width: $px14;
            height: $px14;
            margin: $px2 $px5 0 0;

            span {
              display: inline-block;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              border: $px1 solid #767676;
              position: relative;
              vertical-align: top;
            }

            input {
              display: none;

              &:checked {
                + span {
                  border-color: $colorPoint;

                  &:after {
                    content: " ";
                    display: inline-block;
                    width: $px7;
                    height: $px7;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: $colorPoint;
                    border-radius: 50%;
                  }
                }
              }
            }
          }

          .form-check {
            margin-right: 0;
            padding-left: $px15;
          }

          .form-check-label {
            font-size: $px13;
          }
        }
      }

      &.project {
        .title {
          margin-bottom: 0;
        }

        .cards {
          margin: 0 $px-10;

          li {
            float: left;
            width: calc(100% / 4);
            padding: $gapUnderTitle $px10 $px20 $px10;
          }
        }

        &.new {
          .cards li {
            width: calc(100% / 3);

            &:nth-child(4) {
              display: none;
            }
          }
        }
      }

      &.partners {
        padding: 0 0 $px65;

        .side {
          top: $px-3;
        }
      }

      &.news {
        > .newses {
          margin: 0 $px-10;

          > div {
            padding: 0 $px10;

            .wrapper {
              margin-bottom: $px20;
              position: relative;
              cursor: pointer;

              .thumbnail {
                width: 25%;
                overflow: hidden;

                > div {
                  padding-top: $ratio43Percent;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position: 50% 50%;
                  width: 100%;
                  background-color: #f7f7f7;
                  transition: transform 0.18s;
                }
              }


              .right-side {
                width: 75%;
                padding: $px15;
                position: absolute;
                top: 0;
                right: 0;

                .top {
                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: flex;
                  align-items: flex-end;
                  justify-content: space-between;

                  .info {
                    display: flex;
                    overflow: hidden;

                    .flag {
                      padding-right: $px6;
                      margin-right: $px6;
                      position: relative;
                      white-space: nowrap;

                      &::before {
                        content: " ";
                        display: inline-block;
                        width: 1%;
                        height: 70%;
                        background: #212529;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                      }
                    }

                    .project-name {
                      display: block;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }

                  span {
                    font-size: $px12;
                    display: inline-block;
                    vertical-align: bottom;

                    &.date {
                      padding-left: $px6;
                      white-space: nowrap;
                    }
                  }
                }

                .news-title {
                  margin-top: $px10;
                  font-size: $px15;

                  > b {
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                  }
                }
              }

              &:hover {
                .thumbnail > div {
                  transform: scale(1.1);
                }
              }
            }

            &.skeleton {
              .wrapper {
                cursor: default;

                .thumbnail > div {
                  @include skeleton;
                }

                .right-side {
                  .top span, .news-title {
                    @include skeleton;
                  }

                  .top span.flag {
                    &::before {
                      width: 0;
                    }
                  }
                }
              }
            }
          }
        }

        > .actions {
          position: relative;
          text-align: center;

          > .btn {
            padding: $px14 $px29;
            font-size: $px12;

            &:hover {
              color: inherit;
            }
          }

          > .fold {
            cursor: pointer;
            font-size: $px13;
            padding: $px8;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          &.skeleton {
            > button {
              @include skeleton;
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.skeleton {
        .title {
          a, b, span {
            @include skeleton;
          }
        }

        .side {
          a, label {
            @include skeleton;
          }
        }
      }
    }

    .banner {
      font-family: "NanumSquareNeo", "Pretendard", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
      margin-bottom: $px45;
      min-height: $px139;

      > a {
        display: block;
        padding: $px35 $px10;
        text-align: center;
        width: 100%;
        color: #fff;
        position: relative;
        text-decoration: none;

        .background {
          display: block;
          background-color: $colorBackground;
          background-size: cover;
          background-position: center;
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
        }

        .title {
          position: relative;
          word-break: keep-all;

          .top {
            font-size: $px24;
            font-weight: 600;
          }

          .sub {
            font-size: $px18;
            font-weight: 500;
            margin-top: $px5;
          }
        }

        .btn {
          position: relative;
          margin-top: $px21;
          padding: $px13 $px25;
          color: #fff;
          border: $px1 solid #fff;

          span {
            vertical-align: middle;
            margin: 0 $px5;

            &.img {
              width: $px20;
              height: $px20;
            }
          }

          &:hover {
            background: #fff;
            color: $colorTextDefault;
          }
        }

        h2 {
          font-size: 1.7em;
          font-weight: 600;
          line-height: 1.3;
        }

        h3 {
          font-size: $px22;
          font-weight: 500;
        }

        p {
          font-size: 1rem;
          margin: $px9 0 0 0;
        }
      }
    }
  }

  > .news-letter {
    position: fixed;
    right: 0;
    bottom: 0;
    width: $px140;
    height: $px140;
    z-index: 1;
    transition: bottom 0.18s;

    .ico {
      background: url("/assets/ico/page.main.home.news-letter.svg") center/contain no-repeat;
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    max-width: 540px;
    border: 1px solid #eee;
    border-top: 7px solid #27caa1;
    background: #fff;

    b {
      display: block;
      padding-bottom: 5px;
      font-weight: bold;
    }

    > .wrapper {
      padding: 30px;
    }

    .bottom {
      display: flex;
      width: 100%;
      border-top: 1px solid #eee;

      > div {
        width: 100%;
        text-align: center;

        a {
          display: block;
          padding: 15px;
          cursor: pointer;
        }
      }
    }
  }

  @media(max-width: 1200px) {
    > .content {
      .section {
        &.news {
          > .newses > div .wrapper {
            .right-side {
              .news-title > b {
                -webkit-line-clamp: 1;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    > .slider {
      padding: 0;
    }

    > .content {
      margin-top: $px17;

      .section {
        .title {
          margin-bottom: $gapUnderTitleMobile;

          h3 {
            font-size: $px18;
          }

          .desc {
            margin-top: $px4;
            font-size: $px12;
          }
        }

        &.project {
          .title {
            margin-bottom: 0;
          }

          .cards {
            margin: 0 $px-15;
            white-space: nowrap;
            overflow: auto;

            li {
              white-space: normal;
              float: none;
              display: inline-block;
              width: calc(100% / 2.5);
              padding: $gapUnderTitleMobile $px8 $px18 $px8;

              &:first-child {
                margin-left: $px8;
              }

              &:last-child {
                margin-right: $px8;
              }
            }
          }

          &.new {
            .cards li {
              width: calc(100% / 2.5);

              &:nth-child(4) {
                display: inline-block;
              }
            }
          }
        }

        &.news {
          > .newses > div {
            .wrapper {
              .right-side {
                .top {
                  span {
                    &.date {
                      font-size: $px10;
                    }
                  }
                }

                .news-title > b {
                  -webkit-line-clamp: 2;
                }
              }
            }
          }

          .actions {
            > .btn {
              padding: $px8 $px16;
            }
          }
        }

        &.partners {
          padding-bottom: $px30;
        }
      }

      .banner {
        min-height: auto;

        > a {
          padding-top: $px36;
          padding-bottom: $px36;

          .title {
            .top {
              font-size: $px18;
            }

            .sub {
              font-size: $px12;
            }
          }
        }
      }
    }
  }

  @media(max-width: 767px) {
    > .content {
      .section {
        &.news {
          > .newses > div .wrapper {
            .right-side {
              .news-title {
                > b {
                  -webkit-line-clamp: 1;
                }
              }
            }
          }
        }
      }
    }

    > .news-letter {
      display: none;
    }
  }

  @media(max-width: 575px) {
    > .content {
      .section {
        &.news {
          > .newses > div .wrapper {
            .right-side {
              .top span {
                font-size: $px10;

                &.project-name {
                  font-size: $px10;
                }
              }

              .news-title {
                font-size: $px12;
              }
            }
          }
        }
      }

      .banner {
        margin-left: $px-15;
        margin-right: $px-15;
      }
    }
  }

  @media(max-width: 350px) {
    > .content {
      .section {
        &.news {
          > .newses > div .wrapper {
            .right-side {
              .top {
                display: none;
              }

              .news-title {
                margin-top: 0;

                > b {
                  font-size: $px10;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>